import { Injectable } from '@angular/core';
import { FrontEnergyIncentive } from '@shared/models/business/frontEnergyIncentive';
import { VehicleCostFormService } from '../forms/vehicle-cost-form.service';
import { GlobalConstant } from '@shared/GlobalConstant';

//TODO: ALL ALERT SIGNAL NEED TO BE REFONT

@Injectable({
	providedIn: 'root',
})
export class AlertSignalService {
	public alertSignalsList: Array<String> = []; // distinct values !!
	public alertMaps: Map<String, Array<String[]>> = new Map<String, Array<String[]>>();
	/** Allow to display alert messages*/
	public errMsgAppDisplay: boolean = false;
	public errMsgOpDaysPerYearDisplay: boolean = false;
	public errMsgShiftYearDisplay: boolean = false;
	public errMsgDailyMileageDisplay: boolean = false;
	public errMsgYearlyMileageDisplay: boolean = false;
	public errMsgContractMonthDisplay: boolean = false;
	public errMsgContractYearDisplay: boolean = false;
	public errModelDisplay: boolean = false;
	public errPtacDisplay: boolean = false;
	public errPtoDisplay: boolean = false;
	public errBatteryDisplay: boolean = false;
	/** Allow to trigger opening modal if value are setted in Financials*/
	public modalAppIsTriggered: boolean = false;
	public modalOpsIsTriggered: boolean = false;
	public modalShiftsIsTriggered: boolean = false;
	public modalDailyMileageIsTriggered: boolean = false;
	public modalYearlyMileageIsTriggered: boolean = false;
	public modalYearContractIsTriggered: boolean = false;
	public modalMonthsContractIsTriggered: boolean = false;
	public modalModelIsTriggered: boolean = false;
	public modalPtacIsTriggered: boolean = false;
	public modalPtoIsTriggered: boolean = false;
	public modalBatteryIsTriggered: boolean = false;
	/** Allow to highlight input with impact*/
	// ENERGY USAGE
	public isAverageConsHighlighted: boolean;
	// DETAILED
	public isTotalNetPriceHighlighted: boolean;
	public isResidualValueHighlighted: boolean;
	public isBodyEqHighlighted: boolean;
	// MONTHLY
	public isFeesTruckBodyHighlighted: boolean;
	public isChargingStationHighlighted: boolean;
	//Both
	public isRmCostHighlighted: boolean;

	constructor(protected vehicleCostFormService: VehicleCostFormService) {}

	/**
	 * Init Alert signal when changes are detected in inputs
	 * @param fieldName
	 * @param energy
	 */
	public initAlertSignalByInputs(fieldName: string, isDetailed: boolean, energy?: string) {
		switch (fieldName) {
			case 'application':
				this.addConsumptionAlert();
				this.addRMCostAlert();
				if (isDetailed) {
					this.addBodyAlert();
					this.addRVAlert();
					this.alertMaps.set(fieldName, [
						['energyUsage.consumption'],
						['financials.body', 'financials.residual', 'financials.rm'],
					]);
				} else {
					this.addMonthlyFeesTruckAlert();
					this.alertMaps.set(fieldName, [['energyUsage.consumption'], ['financials.monthlyFeesTruck', 'financials.rm']]);
				}
				break;
			case 'operatingDaysPerYear':
			case 'shiftPerDay':
			case 'dailyMileage':
			case 'yearlyMileage':
			case 'contractMonth':
			case 'contractYear':
				this.addRMCostAlert();
				if (isDetailed) {
					this.addRVAlert();
					this.alertMaps.set(fieldName, [['financials.residual', 'financials.rm']]);
				} else {
					this.addMonthlyFeesTruckAlert();
					if (fieldName === 'contractMonth' || fieldName === 'contractYear') {
						this.addMonthlyFeeChargerAlert();
						this.alertMaps.set(fieldName, [['financials.monthlyFeesTruck', 'financials.rm', 'financials.monthlyFeeCharger']]);
					} else {
						this.alertMaps.set(fieldName, [['financials.monthlyFeesTruck', 'financials.rm']]);
					}
				}
				break;
			case 'model':
			case 'battery':
			case 'ptac':
			case 'withPto':
				if (energy && energy === GlobalConstant.ELECTRIC) {
					this.addRMCostAlert();
					if (isDetailed) {
						this.addRVAlert();
						this.addTruckAlert();
						this.alertMaps.set(fieldName, [['financials.truck', 'financials.residual', 'financials.rm']]);
					} else {
						this.addMonthlyFeesTruckAlert();
						this.alertMaps.set(fieldName, [['financials.monthlyFeesTruck', 'financials.rm']]);
					}
				}
				break;
		}
	}

	/**
	 * Get Alert Signal triggered by input changed
	 * @param comparisonsSelected
	 */
	public getAlertSignals(comparisonsSelected: FrontEnergyIncentive[], isDisplayed: boolean, isTriggered: boolean, formControl: any) {
		comparisonsSelected.forEach((energy) => {
			this.alertSignalsList.forEach((field) => {
				// @ts-ignore
				if (formControl[energy.name].get(field)?.value > 0) {
					this.switchAlertSignalStatus(isDisplayed, isTriggered, String(field));
				}
			});
		});
	}

	/**
	 * Switch inputs statut in financial component when changed are detected (highlight or not the input)
	 * @param isDisplayed
	 * @param x
	 */
	public switchAlertSignalStatus(isDisplayed: boolean, isTriggered: boolean, field: string) {
		switch (field) {
			case 'truck':
				this.isTotalNetPriceHighlighted = isDisplayed;
				this.modalModelIsTriggered = isTriggered;
				this.modalPtacIsTriggered = isTriggered;
				this.modalPtoIsTriggered = isTriggered;
				this.modalBatteryIsTriggered = isTriggered;
				this.removeElmInAlertSignal('truck');
				break;
			case 'body':
				this.isBodyEqHighlighted = isDisplayed;
				this.modalAppIsTriggered = isTriggered;
				this.removeElmInAlertSignal('body');
				break;
			case 'residualValue':
				this.isResidualValueHighlighted = isDisplayed;
				this.modalAppIsTriggered = isTriggered;
				this.modalOpsIsTriggered = isTriggered;
				this.modalShiftsIsTriggered = isTriggered;
				this.modalDailyMileageIsTriggered = isTriggered;
				this.modalYearlyMileageIsTriggered = isTriggered;
				this.modalYearContractIsTriggered = isTriggered;
				this.modalMonthsContractIsTriggered = isTriggered;
				this.modalModelIsTriggered = isTriggered;
				this.modalPtacIsTriggered = isTriggered;
				this.modalPtoIsTriggered = isTriggered;
				this.modalBatteryIsTriggered = isTriggered;
				this.removeElmInAlertSignal('residualValue');
				break;
			case 'monthlyFeeCharger':
				this.isChargingStationHighlighted = isDisplayed;
				this.modalYearContractIsTriggered = isTriggered;
				this.modalMonthsContractIsTriggered = isTriggered;
				this.removeElmInAlertSignal('monthlyFeeCharger');
				break;
			case 'monthlyFeesTruck':
				this.isFeesTruckBodyHighlighted = isDisplayed;
				this.modalAppIsTriggered = isTriggered;
				this.modalOpsIsTriggered = isTriggered;
				this.modalShiftsIsTriggered = isTriggered;
				this.modalDailyMileageIsTriggered = isTriggered;
				this.modalYearlyMileageIsTriggered = isTriggered;
				this.modalYearContractIsTriggered = isTriggered;
				this.modalMonthsContractIsTriggered = isTriggered;
				this.modalModelIsTriggered = isTriggered;
				this.modalPtacIsTriggered = isTriggered;
				this.modalPtoIsTriggered = isTriggered;
				this.modalBatteryIsTriggered = isTriggered;
				this.removeElmInAlertSignal('monthlyFeesTruck');
				break;
			case 'rmContract':
				this.isRmCostHighlighted = isDisplayed;
				this.modalAppIsTriggered = isTriggered;
				this.modalOpsIsTriggered = isTriggered;
				this.modalShiftsIsTriggered = isTriggered;
				this.modalDailyMileageIsTriggered = isTriggered;
				this.modalYearlyMileageIsTriggered = isTriggered;
				this.modalYearContractIsTriggered = isTriggered;
				this.modalMonthsContractIsTriggered = isTriggered;
				this.modalModelIsTriggered = isTriggered;
				this.modalPtacIsTriggered = isTriggered;
				this.modalPtoIsTriggered = isTriggered;
				this.modalBatteryIsTriggered = isTriggered;
				this.removeElmInAlertSignal('rmContract');
				break;
		}
	}

	/**
	 * Display Alert Message for each input
	 * @param property
	 */
	public displayAlertMessageError(property: string) {
		switch (property) {
			case 'application':
				this.modalAppIsTriggered === true ? (this.errMsgAppDisplay = true) : (this.errMsgOpDaysPerYearDisplay = false);
				break;
			case 'operatingDaysPerYear':
				this.modalOpsIsTriggered === true ? (this.errMsgOpDaysPerYearDisplay = true) : (this.errMsgOpDaysPerYearDisplay = false);
				break;
			case 'shiftPerDay':
				this.modalShiftsIsTriggered === true ? (this.errMsgShiftYearDisplay = true) : (this.errMsgShiftYearDisplay = false);
				break;
			case 'dailyMileage':
				this.modalDailyMileageIsTriggered === true
					? (this.errMsgDailyMileageDisplay = true)
					: (this.errMsgDailyMileageDisplay = false);
				break;
			case 'yearlyMileage':
				this.modalYearlyMileageIsTriggered === true
					? (this.errMsgYearlyMileageDisplay = true)
					: (this.errMsgYearlyMileageDisplay = false);
				break;
			case 'contractYear':
				this.modalYearContractIsTriggered === true
					? (this.errMsgContractYearDisplay = true)
					: (this.errMsgContractYearDisplay = false);
				break;
			case 'contractMonth':
				this.modalMonthsContractIsTriggered === true
					? (this.errMsgContractMonthDisplay = true)
					: (this.errMsgContractMonthDisplay = false);
				break;
			case 'model':
				this.modalModelIsTriggered === true ? (this.errModelDisplay = true) : (this.errModelDisplay = false);
				break;
			case 'battery':
				this.modalBatteryIsTriggered === true ? (this.errBatteryDisplay = true) : (this.errBatteryDisplay = false);
				break;
			case 'withPto':
				this.modalPtoIsTriggered === true ? (this.errPtoDisplay = true) : (this.errPtoDisplay = false);
				break;
			case 'ptac':
				this.modalPtacIsTriggered === true ? (this.errPtacDisplay = true) : (this.errPtacDisplay = false);
				break;
		}
	}

	public removeElmInAlertSignal(formControlName: string) {
		this.alertSignalsList = this.alertSignalsList.filter((item) => item !== formControlName);
	}

	public clearAlertSignal() {
		this.alertSignalsList = [];
	}

	public populeAlertSignalsListForDetailed() {
		this.addConsumptionAlert();
		this.addRMCostAlert();
		this.addBodyAlert();
		this.addRVAlert();
	}

	public populeAlertSignalsListForMonthly() {
		this.addConsumptionAlert();
		this.addRMCostAlert();
		this.addMonthlyFeesTruckAlert();
		this.addMonthlyFeesTruckAlert();
	}

	private addBodyAlert() {
		if (!this.alertSignalsList.includes('body')) {
			this.alertSignalsList.push('body');
		}
	}

	private addConsumptionAlert() {
		if (!this.alertSignalsList.includes('consumption')) {
			this.alertSignalsList.push('consumption');
		}
	}

	private addRMCostAlert() {
		if (!this.alertSignalsList.includes('rmContract')) {
			this.alertSignalsList.push('rmContract');
		}
	}

	private addRVAlert() {
		if (!this.alertSignalsList.includes('residualValue')) {
			this.alertSignalsList.push('residualValue');
		}
	}

	private addMonthlyFeesTruckAlert() {
		if (!this.alertSignalsList.includes('monthlyFeesTruck')) {
			this.alertSignalsList.push('monthlyFeesTruck');
		}
	}

	private addTruckAlert() {
		if (!this.alertSignalsList.includes('truck')) {
			this.alertSignalsList.push('truck');
		}
	}

	private addMonthlyFeeChargerAlert() {
		if (!this.alertSignalsList.includes('monthlyFeeCharger')) {
			this.alertSignalsList.push('monthlyFeeCharger');
		}
	}

	/**
	 * Reset All alerts
	 */
	public resetAllAlerts() {
		this.isRmCostHighlighted = false;
		this.isTotalNetPriceHighlighted = false;
		this.isResidualValueHighlighted = false;
		this.isBodyEqHighlighted = false;
		this.isFeesTruckBodyHighlighted = false;
		this.isChargingStationHighlighted = false;
		this.isAverageConsHighlighted = false;

		this.errMsgAppDisplay = false;
		this.errMsgOpDaysPerYearDisplay = false;
		this.errMsgShiftYearDisplay = false;
		this.errMsgDailyMileageDisplay = false;
		this.errMsgYearlyMileageDisplay = false;
		this.errMsgContractMonthDisplay = false;
		this.errMsgContractYearDisplay = false;
		this.errModelDisplay = false;
		this.errPtacDisplay = false;
		this.errPtoDisplay = false;
		this.errBatteryDisplay = false;

		this.modalAppIsTriggered = false;
		this.modalOpsIsTriggered = false;
		this.modalShiftsIsTriggered = false;
		this.modalDailyMileageIsTriggered = false;
		this.modalYearlyMileageIsTriggered = false;
		this.modalYearContractIsTriggered = false;
		this.modalMonthsContractIsTriggered = false;
		this.modalModelIsTriggered = false;
		this.modalPtacIsTriggered = false;
		this.modalPtoIsTriggered = false;
		this.modalBatteryIsTriggered = false;
	}

	/**
	 * Close all Message error modal
	 */
	public closeAllAlertModal() {
		this.errMsgAppDisplay = false;
		this.errMsgOpDaysPerYearDisplay = false;
		this.errMsgShiftYearDisplay = false;
		this.errMsgDailyMileageDisplay = false;
		this.errMsgYearlyMileageDisplay = false;
		this.errMsgContractMonthDisplay = false;
		this.errMsgContractYearDisplay = false;
		this.errModelDisplay = false;
		this.errPtacDisplay = false;
		this.errPtoDisplay = false;
		this.errBatteryDisplay = false;
	}
}
