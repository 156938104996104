import { GlobalInformation } from '@_models/globalInformation';
import { VehicleCost } from '@_models/vehicleCost';
import { VehicleConfig } from '@_models/vehicleInfo/vehicleConfig/vehicleConfig';
import { VehicleUsage } from '@_models/vehicleInfo/vehicleUsage/vehicleUsage';
import { VehicleInformation } from '@_models/vehicleInformation';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { FrontEnergyIncentive } from '@shared/models/business/frontEnergyIncentive';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ILanguage } from '../../../components/header/header.component';
import { ComparisonDto } from '../../_dto/global/comparisonDto';
import { Comparison } from '../../_models/comparison';
import { EmissionFactorService } from '../business/emission-factor.service';
import { VehicleCostFormService } from '../forms/vehicle-cost-form.service';
import { GlobalInfoService } from './global-info.service';
import { SessionStorageService } from './session-storage.service';
import { VehicleCostService } from './vehicle-cost.service';
import { VehicleInformationService } from './vehicle-information-service';

type DataTypes = VehicleUsage | GlobalInformation | VehicleConfig | any;

@Injectable({
	providedIn: 'root',
})
export class ComparatorService {
	private comparisonObs$: BehaviorSubject<Comparison> = new BehaviorSubject(new Comparison());
	// Boolean to hide buttons in header (depending of routing)
	private hideButtonsObs$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	// Event Emitter allow to update data from Module to module (by calling method)
	public invokeComponentMethod = new EventEmitter();
	public subsVar: Subscription;
	public selectedLang: ILanguage = { code: '', label: '' };
	private supportedLanguages = ['de', 'es', 'it', 'nl', 'fr', 'en'];

	constructor(
		private translateService: TranslateService,
		private sessionStorageService: SessionStorageService,
		private globalInfoService: GlobalInfoService,
		private vehicleCostService: VehicleCostService,
		private emissionFactorService: EmissionFactorService,
		private vehicleInfoService: VehicleInformationService,
		protected vehicleCostFormService: VehicleCostFormService
	) {}

	public invokeMethodFromOutside() {
		this.invokeComponentMethod.emit();
	}

	/**
	 * Get Comparison: allow to read the value
	 * @returns
	 */
	public getComparisonObs(): Observable<Comparison> {
		return this.comparisonObs$.asObservable();
	}

	/**
	 * Set Comparison: allow to write value
	 * @param comparison
	 */
	public setComparisonObs(comparison: Comparison) {
		this.comparisonObs$.next(comparison);
	}

	public initDefaultComparison(comparison: Comparison): Comparison {
		const comparisonTypeId = comparison.globalInfo.comparisonType.id;
		const isDetail = comparison.globalInfo.isDetail;
		comparison.vehicleInfo = this.setDefaultVehicleInformation(comparisonTypeId);
		comparison.vehicleCost = this.setDefaultVehicleCost(isDetail, comparisonTypeId);
		this.emissionFactorService.setComparisonForEmissionFactor(comparison);
		comparison.vehicleCost.impactCo2 = this.emissionFactorService.refreshEmissionFactorByEnergy(
			comparison.globalInfo.comparisonType.id,
			'GB',
			this.selectedLang.code
		);

		return comparison;
	}

	private setDefaultVehicleInformation(comparisonTypeId: number): VehicleInformation {
		return this.vehicleInfoService.loadDefaultVehicleInformation(comparisonTypeId);
	}

	private setDefaultVehicleCost(isDetail: boolean, comparisonTypeId: number): VehicleCost {
		return this.vehicleCostService.loadDefaultVehicleCost(comparisonTypeId);
	}

	public LoadComparisonData(compDto: ComparisonDto): Comparison {
		let comp = new Comparison();
		try {
			comp.globalInfo = this.globalInfoService.getGlobalInfoFromDto(compDto.comparisonMetadata);
			comp.vehicleInfo = this.vehicleInfoService.loadVehicleInfoData(compDto);
			comp.vehicleCost = this.vehicleCostService.loadVehicleCostData(compDto);
			this.emissionFactorService.setComparisonForEmissionFactor(comp);
		} catch (error) {
			console.error('Error when loading comparison data', error);
		}
		return comp;
	}

	/**
	 * Update Data value at every change detected in form
	 *
	 * @param input             Allow to know which object's property has to change
	 * @param form              From which Form
	 * @param data              Which object model (VehicleUsage | Global Information, etc) it is
	 * @param nestedProperty    Which Nested Object's property is it (optional)
	 */
	public updateValue(input: any, form: FormGroup, data: DataTypes, isEnergy?: boolean) {
		// Get api from forms
		const formValue = form.value;
		// Check if we has to update a nested Object or not
		if (isEnergy) {
			// Update value in Nested Object depending of which property
			data[input.object as keyof typeof data][input.key as keyof typeof data] = formValue[input.object][input.key];
			form.patchValue({ [input.object]: { [input.key]: input.value } });
			this.sessionStorageService.updateValueByEnergy(input.object, data[input.object]);
		} else {
			// Update value in Object depending of which property (Country, Currency, etc)
			data[input.key as keyof typeof data] = input.value;
			form.patchValue({ [input.key]: input.value });
			this.sessionStorageService.updateValueByEnergy(input.key, data);
		}
	}

	/**
	 * Update Data from dropdown in Forms and Object
	 * @param value             New value to set
	 * @param objectName        Which property object is it
	 * @param nestedProperty    Which Nested Object's property is it (optional)
	 * @param form              From which Form
	 * @param dataType          Which Object model it is (VehicleUsage | Global Information, etc)
	 */
	public updateValueFromSelect(value: any, form: FormGroup, dataType: DataTypes, objectName: string, propertyName?: string): void {
		// Check if we has to update a nested Object or not
		if (propertyName) {
			dataType[objectName as keyof typeof dataType][propertyName as keyof typeof dataType] = value;
			form.patchValue({ [objectName]: { [propertyName]: value } });
			objectName = propertyName;
		} else {
			// Update value in Object depending of which property (Country, Currency, etc)
			dataType[objectName as keyof typeof dataType] = value;
			// Update the form dynamicly
			form.patchValue({ [objectName]: value });
		}
	}

	// Build the component according to the comparaison type selected in the home page
	public initComparisonsSelected(comparison: Comparison): FrontEnergyIncentive[] {
		let comparisonsSelected: FrontEnergyIncentive[] = [];

		let electric = {} as FrontEnergyIncentive;
		electric.name = 'electric';
		electric.notUpfrontModal = false;
		electric.upfronts = [];
		electric.taxOver = 0;

		let diesel = {} as FrontEnergyIncentive;
		diesel.name = 'diesel';
		diesel.notUpfrontModal = false;
		diesel.upfronts = [];
		diesel.taxOver = 0;

		let gas = {} as FrontEnergyIncentive;
		gas.name = 'gas';
		gas.notUpfrontModal = false;
		gas.upfronts = [];
		gas.taxOver = 0;

		switch (comparison.globalInfo.comparisonType.id) {
			case GlobalConstant.COMPTYPE_ED:
				comparisonsSelected.push(electric, diesel);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				comparisonsSelected.push(electric, diesel, gas);
				break;
			case GlobalConstant.COMPTYPE_EG:
				comparisonsSelected.push(electric, gas);
				break;
			case GlobalConstant.COMPTYPE_E:
				comparisonsSelected.push(electric);
				break;
			case GlobalConstant.COMPTYPE_EE:
				let electricComp = {} as FrontEnergyIncentive;
				electricComp.name = 'electricComp';
				comparisonsSelected.push(electric, electricComp);
				break;
			default:
				break;
		}
		return comparisonsSelected;
	}

	//TODO: Add symbol in the database (new column) => has to be manage by the backend
	public getCurrencySymbol(currency: string): string {
		switch (currency) {
			case 'EUR':
				return '€';
			case 'GBP':
				return '£';
			case 'CHF':
				return 'CHF';
			case 'NOK':
				return 'NOK';
			case 'DKK':
				return 'DKK';
			default:
				return '€';
		}
	}

	public getHideButtonsObs(): Observable<boolean> {
		return this.hideButtonsObs$.asObservable();
	}

	public setHideButtonsObs(isAlerted: boolean) {
		this.hideButtonsObs$.next(isAlerted);
	}

	public selectLanguage(e: any): void {
		if (this.supportedLanguages.includes(e.code)) {
			this.translateService.use(e.code);
		} else {
			this.translateService.use('en');
		}
	}

	public initLanguage(): void {
		// Get the language from the navigator
		const defaultLanguage = 'en';
		let langCode = sessionStorage.getItem('lang') || navigator.language.slice(0, 2).toLowerCase();
		if (!this.supportedLanguages.includes(langCode)) {
			langCode = defaultLanguage;
		}
		// Set selected language
		this.selectedLang = this.getLanguageInfo(langCode);
		// Set language for translations on init
		this.translateService.use(this.supportedLanguages.includes(langCode) ? langCode : defaultLanguage);
	}

	private getLanguageInfo(langCode: string): { code: string; label: string } {
		switch (langCode) {
			case 'fr':
				return { code: 'fr', label: 'Français' };
			default:
				return { code: 'en', label: 'English' };
		}
	}
}
