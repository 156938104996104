import { ComparisonDto } from '@_dto/global/comparisonDto';
import { EnergyUsageDto } from '@_dto/global/vehicleInfoDto/energyUsageDto';
import { VehicleConfigDto } from '@_dto/global/vehicleInfoDto/vehicleConfigDto';
import { EnergyConsumption } from '@_models/vehicleInfo/energyUsage/energyConsumption';
import { EnergyUsage } from '@_models/vehicleInfo/energyUsage/energyUsage';
import { ContractDuration } from '@_models/vehicleInfo/usageLimit/contractDuration';
import { DailyMission } from '@_models/vehicleInfo/usageLimit/dailyMission';
import { TotalDeliveryEnergy } from '@_models/vehicleInfo/usageLimit/totalDeliveryEnergy';
import { UsageLimit } from '@_models/vehicleInfo/usageLimit/usageLimit';
import { EnergyConfig } from '@_models/vehicleInfo/vehicleConfig/energyConfig';
import { VehicleConfig } from '@_models/vehicleInfo/vehicleConfig/vehicleConfig';
import { VehicleUsage } from '@_models/vehicleInfo/vehicleUsage/vehicleUsage';
import { VehicleInformation } from '@_models/vehicleInformation';
import { Injectable } from '@angular/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { BatteryVersion } from '@shared/models/business/batteryVersion';
import { EngineNumber } from '@shared/models/business/engineNumber';
import { ModelEnergy } from '@shared/models/business/modelEnergy';
import { Ptac } from '@shared/models/business/ptac';
import { TcoBackendService } from '../api/tco-backend.service';

@Injectable({
	providedIn: 'root',
})
export class VehicleInformationService {
	constructor(private dataService: TcoBackendService) {}

	/**  Init Default Vehicle Information if api are not load from a save */
	public loadDefaultVehicleInformation(comparisonId: number): VehicleInformation {
		let vehicleInfo = new VehicleInformation();
		vehicleInfo.vehicleUsage = new VehicleUsage();
		this.loadDefaultVehicleUsage(vehicleInfo);
		vehicleInfo.vehicleConfig = new VehicleConfig();
		this.loadDefaultVehicleConfigByComparisonType(vehicleInfo.vehicleConfig, comparisonId);
		vehicleInfo.energyUsage = new EnergyUsage();
		this.loadDefaultEnergyUsageByComparisonType(vehicleInfo.energyUsage, comparisonId);
		vehicleInfo.usageLimit = new UsageLimit();
		this.loadDefaultLimitUsage(vehicleInfo);
		return vehicleInfo;
	}

	/**  Init Default Vehicle Usage if api are not load from a save */
	private loadDefaultVehicleUsage(vehicleInfo: VehicleInformation) {
		vehicleInfo.vehicleUsage.contractMonth = 0;
		vehicleInfo.vehicleUsage.contractYear = 0;
		vehicleInfo.vehicleUsage.dailyMileage = 0;
		vehicleInfo.vehicleUsage.yearlyMileage = 0;
		vehicleInfo.vehicleUsage.operatingDaysPerYear = 0;
		vehicleInfo.vehicleUsage.shiftPerDay = 0;
	}

	/**  Init Default Vehicle Config By Comparison type  if api are not load from a save */
	public loadDefaultVehicleConfigByComparisonType(vehicleConfig: VehicleConfig, comparisonId: number) {
		this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.ELECTRIC);
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.DIESEL);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.DIESEL);
				this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EG:
				this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EE:
				this.loadDefaultVehicleConfigByEnergy(vehicleConfig, GlobalConstant.ELEC_COMP);
				break;
		}
	}

	public loadDefaultVehicleConfigByEnergy(vehicleConfig: VehicleConfig, energy: string) {
		let vehicleConfigMock: any = vehicleConfig;
		vehicleConfigMock[energy] = new EnergyConfig();
		vehicleConfigMock[energy].payload = 0;
		if (energy != GlobalConstant.ELECTRIC) {
			const model = new ModelEnergy();
			model.name = '';
			model.code = '';
			vehicleConfigMock[energy].model = model;
		}
		vehicleConfig = vehicleConfigMock;
	}

	/**  Init Default Energy usage By Comparison type if api are not load from a save */
	public loadDefaultEnergyUsageByComparisonType(energyUsage: EnergyUsage, comparisonId: number) {
		this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.ELECTRIC);
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.DIESEL);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.DIESEL);
				this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EG:
				this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EE:
				this.loadDefaultEnergyUsageByEnergy(energyUsage, GlobalConstant.ELEC_COMP);
				break;
		}
	}

	/**  Init Default Energ Usage By energy if api are not load from a save */
	public loadDefaultEnergyUsageByEnergy(energyUsage: EnergyUsage, energy: string) {
		let energyUsageMock: any = energyUsage;
		energyUsageMock[energy] = new EnergyConsumption();
		energyUsageMock[energy].consumption = 0;
		if (energy === GlobalConstant.DIESEL) {
			energyUsageMock[energy].adblue = 0;
			energyUsageMock[energy].consumption = 0;
		}
		energyUsage = energyUsageMock;
	}

	/**  Init Default Energy Usage if api are not load from a save */
	private loadDefaultLimitUsage(vehicleInfo: VehicleInformation) {
		// Daily Mission value
		vehicleInfo.usageLimit = new UsageLimit();
		vehicleInfo.usageLimit.dailyMission = {} as DailyMission;
		vehicleInfo.usageLimit.dailyMission.maxBeginningOfLife = 0;
		vehicleInfo.usageLimit.dailyMission.maxEndOfLife = 0;
		vehicleInfo.usageLimit.dailyMission.simulation = 0;
		vehicleInfo.usageLimit.dailyMission.status = '';
		// Total delivered energy
		vehicleInfo.usageLimit.totalDeliveredEnergy = {} as TotalDeliveryEnergy;
		vehicleInfo.usageLimit.totalDeliveredEnergy.simulation = 0;
		vehicleInfo.usageLimit.totalDeliveredEnergy.maxEnergy = 0;
		vehicleInfo.usageLimit.totalDeliveredEnergy.status = '';
		// Total delivered energy
		vehicleInfo.usageLimit.contractDuration = {} as ContractDuration;
		vehicleInfo.usageLimit.contractDuration.simulation = 0;
		vehicleInfo.usageLimit.contractDuration.maxDuration = 0;
		vehicleInfo.usageLimit.contractDuration.status = '';
	}

	/**
	 * Mapping Comparison DTO to Comparison during a loading
	 * @param compDto
	 */
	public loadVehicleInfoData(compDto: ComparisonDto): VehicleInformation {
		let vi = new VehicleInformation();
		vi.vehicleConfig = new VehicleConfig();
		vi.energyUsage = new EnergyUsage();

		if (compDto.energies.length > 0) {
			vi.vehicleUsage = this.getVehicleUsageFromDto(compDto);
			vi.vehicleConfig.electric = this.getEnergyConfigFromDto(
				compDto.energies[0].vehicleConfig,
				compDto.comparisonMetadata.range.id,
				true
			);
			vi.energyUsage.electric = this.getEnergyUsageModelFromDto(compDto.energies[0].energyUsage);
		} else {
			vi.vehicleUsage = new VehicleUsage();
			this.loadDefaultVehicleUsage(vi);
			this.loadDefaultVehicleConfigByComparisonType(vi.vehicleConfig, compDto.comparisonMetadata.range.id);
			this.loadDefaultEnergyUsageByComparisonType(vi.energyUsage, compDto.comparisonMetadata.comparison);
		}

		this.initVehicleInfoByComparison(compDto, vi);
		this.loadDefaultLimitUsage(vi);
		return vi;
	}

	private initVehicleInfoByComparison(compDto: ComparisonDto, vi: VehicleInformation) {
		switch (compDto.comparisonMetadata.comparison) {
			case GlobalConstant.COMPTYPE_ED:
				vi.energyUsage.diesel = this.getEnergyUsageModelFromDto(compDto.energies[1].energyUsage);
				vi.vehicleConfig.diesel = this.getEnergyConfigFromDto(
					compDto.energies[1].vehicleConfig,
					compDto.comparisonMetadata.range.id,
					false
				);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				vi.energyUsage.diesel = this.getEnergyUsageModelFromDto(compDto.energies[1].energyUsage);
				vi.energyUsage.gas = this.getEnergyUsageModelFromDto(compDto.energies[2].energyUsage);
				vi.vehicleConfig.diesel = this.getEnergyConfigFromDto(
					compDto.energies[1].vehicleConfig,
					compDto.comparisonMetadata.range.id,
					false
				);
				vi.vehicleConfig.gas = this.getEnergyConfigFromDto(
					compDto.energies[2].vehicleConfig,
					compDto.comparisonMetadata.range.id,
					false
				);
				break;
			case GlobalConstant.COMPTYPE_EG:
				vi.energyUsage.gas = this.getEnergyUsageModelFromDto(compDto.energies[1].energyUsage);
				vi.vehicleConfig.gas = this.getEnergyConfigFromDto(
					compDto.energies[1].vehicleConfig,
					compDto.comparisonMetadata.range.id,
					false
				);
				break;
			case GlobalConstant.COMPTYPE_EE:
				vi.energyUsage.electricComp = this.getEnergyUsageModelFromDto(compDto.energies[1].energyUsage);
				vi.vehicleConfig.electricComp = this.getEnergyConfigFromDto(
					compDto.energies[1].vehicleConfig,
					compDto.comparisonMetadata.range.id,
					false
				);
				break;
		}
	}

	private getEnergyUsageModelFromDto(energyUsage: EnergyUsageDto): EnergyConsumption {
		let ec = new EnergyConsumption();
		ec.consumption = energyUsage.consumption ? energyUsage.consumption : 0;
		ec.adblue = energyUsage.adblue ? energyUsage.adblue : 0;
		return ec;
	}

	private getVehicleUsageFromDto(compDto: ComparisonDto): VehicleUsage {
		let vu = new VehicleUsage();
		vu.application = compDto.energies[0].vehicleUsage.application;
		if (compDto.comparisonMetadata.range.id != GlobalConstant.RANGE_LCV) {
			vu.bodyType = compDto.comparisonMetadata.bodyType;
			vu.usageType = compDto.comparisonMetadata.usageType;
		}
		vu.operatingDaysPerYear = compDto.energies[0].vehicleUsage.operatingDaysPerYear
			? compDto.energies[0].vehicleUsage.operatingDaysPerYear
			: 0;
		vu.shiftPerDay = compDto.energies[0].vehicleUsage.shiftPerDay ? compDto.energies[0].vehicleUsage.shiftPerDay : 0;
		vu.yearlyMileage = compDto.energies[0].vehicleUsage.input.yearlyMileage ? compDto.energies[0].vehicleUsage.input.yearlyMileage : 0;
		vu.contractYear = compDto.energies[0].vehicleUsage.input.contractYear ? compDto.energies[0].vehicleUsage.input.contractYear : 0;
		vu.contractMonth = compDto.energies[0].vehicleUsage.input.contractMonth ? compDto.energies[0].vehicleUsage.input.contractMonth : 0;
		vu.dailyMileage = compDto.energies[0].vehicleUsage.input.dailyMileage ? compDto.energies[0].vehicleUsage.input.dailyMileage : 0;
		return vu;
	}

	private getEnergyConfigFromDto(vehicleConfig: VehicleConfigDto, rangeId: number, isElec: boolean): EnergyConfig {
		let ec = new EnergyConfig();
		ec.model = { code: vehicleConfig.modelNameCode } as ModelEnergy;
		ec.pto = vehicleConfig.pto;
		this.initVehicleConfigByRangeId(rangeId, vehicleConfig, ec, isElec);
		ec.payload = vehicleConfig.payload ? vehicleConfig.payload : 0;
		ec.battery = new BatteryVersion();
		ec.battery.code = vehicleConfig.batteryCode ? vehicleConfig.batteryCode : '';
		ec.energySubtype = vehicleConfig.energySubtype;
		return ec;
	}

	private initVehicleConfigByRangeId(rangeId: number, vehicleConfigDto: VehicleConfigDto, ec: EnergyConfig, isElec: boolean) {
		if (rangeId === GlobalConstant.RANGE_HDV) {
			this.initEngineNumberForElec(vehicleConfigDto, ec, isElec);
			ec.gcw = vehicleConfigDto.gcw;
		} else {
			ec.ptac = vehicleConfigDto.ptac as Ptac;
		}
	}

	// TODO: Need to be improve, but need to rethink back request/modeling
	private initEngineNumberForElec(vehicleConfigDto: VehicleConfigDto, ec: EnergyConfig, isElec: boolean) {
		if (vehicleConfigDto.engineNumber) {
			ec.engineNumber = new EngineNumber();
			if (isElec) {
				this.dataService.getEngineNumberById(vehicleConfigDto.engineNumber).subscribe((data) => {
					ec.engineNumber = data;
				});
			} else {
				ec.engineNumber.name = String(vehicleConfigDto.engineNumber);
			}
		}
	}
}
