import { Application } from '@shared/models/business/application';
import { BodyType } from '@shared/models/business/bodyType';
import { UsageType } from '@shared/models/business/usageType';

export class VehicleUsage {
	id: number;
	application: Application;
	bodyType?: BodyType;
	usageType?: UsageType;
	contractMonth: number;
	contractYear: number;
	dailyMileage: number;
	yearlyMileage: number;
	operatingDaysPerYear: number;
	shiftPerDay: number;
}
