import { Pipe, PipeTransform } from '@angular/core';
import { Comparison } from '../../layout/comparator/_models/comparison';

@Pipe({
	name: 'search',
})
export class SearchPipe implements PipeTransform {
	transform(value: Comparison[], args: string): Comparison[] | null {
		if (!value) return null;
		if (!args) return value;

		args = args.toLowerCase();

		return value.filter((item) => {
			return (
				item.globalInfo.filename.toLowerCase().includes(args) ||
				item.globalInfo.caseName.toLowerCase().includes(args) ||
				item.globalInfo.companyName.toLowerCase().includes(args)
			);
		});
	}
}
