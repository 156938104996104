import { ComparisonMetadata } from '@_dto/global/comparisonMetadata';
import { Injectable } from '@angular/core';
import { IComparisonType } from '@shared/models/business/iComparisonType';
import { ICountry } from '@shared/models/business/iCountry';
import { ICurrency } from '@shared/models/business/iCurrency';
import { IRange } from '@shared/models/business/iRange';
import { IUnits } from '@shared/models/business/iUnits';
import { GlobalInformation } from '../../_models/globalInformation';

@Injectable({
	providedIn: 'root',
})
export class GlobalInfoService {
	/**  Init Default Global info if api are not load from a save */
	public loadDefaultGlobalInfo(
		countries: ICountry[],
		ranges: IRange[],
		comparisonTypes: IComparisonType[],
		units: IUnits[],
		lang: string
	): GlobalInformation {
		let defaultCountry = this.getDefaultCountry(countries, lang);
		let globalInfo = new GlobalInformation();
		globalInfo.name = '';
		globalInfo.contactName = '';
		globalInfo.contactMail = '';
		globalInfo.companyName = '';
		globalInfo.caseName = '';
		globalInfo.country = defaultCountry;
		globalInfo.currency = defaultCountry.currency;
		globalInfo.range = ranges[0];
		globalInfo.comparisonType = comparisonTypes[0];
		globalInfo.unit = this.getUnitByCountry(defaultCountry, units);
		globalInfo.isTco = true;
		globalInfo.isDetail = true;
		globalInfo.customerView = 3;
		return globalInfo;
	}

	public resetCustomerInformation(globalInformation: GlobalInformation): GlobalInformation {
		return { ...globalInformation, contactName: '', contactMail: '', companyName: '', caseName: '' };
	}

	public getGlobalInfoFromDto(global: ComparisonMetadata): GlobalInformation {
		let gi = new GlobalInformation();
		gi.id = global.id;
		gi.name = global.name;
		gi.companyName = global.companyName;
		gi.contactMail = global.contactEmail;
		gi.contactName = global.contactName;
		gi.caseName = global.caseName;
		gi.country = global.country.country;
		gi.currency = global.country.currency as ICurrency;
		gi.range = global.range;
		gi.comparisonType = {
			id: global.comparison,
			name: '',
		} as IComparisonType;
		gi.unit = { name: global.dieselConsumptionUnit } as IUnits;
		gi.isTco = global.isTco;
		gi.isDetail = global.isDetail;
		gi.customerView = global.customerView;
		gi.date = global.date;
		return gi;
	}

	/** Init Unit country depending of lang */
	private getUnitByCountry(country: any, units: IUnits[]): any {
		const unit = units.find((elm) => elm.name === country.unit);
		return unit;
	}

	/** Init Default country depending of lang */
	private getDefaultCountry(countries: ICountry[], lang: string): ICountry {
		let defaultCountry = {} as ICountry;
		if (lang === 'en') {
			lang = 'gb';
		}
		countries.forEach((c: ICountry) => {
			if (c.code === lang.toUpperCase()) {
				defaultCountry = c as ICountry;
				return;
			}
		});
		return defaultCountry;
	}
}
