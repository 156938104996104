import { VehicleConfig } from '@_models/vehicleInfo/vehicleConfig/vehicleConfig';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { Utils } from '@shared/utils/utils';
import { ToastrService } from 'ngx-toastr';
import { Comparison } from '../../_models/comparison';
import { EnergySubtype } from '../../_models/vehicleCost/impactCo2/energySubtype';
import { TcoBackendService } from '../api/tco-backend.service';
import { AlertSignalService } from '../logical/alert-signal.service';
import { ComparatorService } from '../logical/comparator.service';
import { GlobalInformationForm } from './global-information-form.service';
import { VehicleCostFormService } from './vehicle-cost-form.service';
import { VehicleInformationFormService } from './vehicle-information-form.service';
import { Observable, of, switchMap, tap } from 'rxjs';
import { EngineNumber } from '@shared/models/engineNumber';
import { ModelEnergy } from '@shared/models/business/modelEnergy';
import { BatteryVersion } from '@shared/models/business/batteryVersion';

@Injectable({
	providedIn: 'root',
})
export class TcoFormService {
	public utils: Utils;
	public isBlock: boolean = true;

	constructor(
		public toastr: ToastrService,
		private router: Router,
		private translate: TranslateService,
		private dataService: TcoBackendService,
		private compService: ComparatorService,
		protected vehicleInfoFormService: VehicleInformationFormService,
		protected vehicleCostFormService: VehicleCostFormService,
		protected globalInfoFormService: GlobalInformationForm,
		protected alertsSignalsService: AlertSignalService
	) {
		this.utils = new Utils(toastr, translate);
	}

	/**
	 * Get Country Emission Factor after loading a comparison
	 * @private
	 */
	public initTcoForms(comparison: Comparison) {
		if (
			comparison.globalInfo.comparisonType.id === GlobalConstant.COMPTYPE_ED ||
			comparison.globalInfo.comparisonType.id === GlobalConstant.COMPTYPE_EDG
		) {
			this.setEnergySubtypeForDiesel(comparison.vehicleInfo.vehicleConfig);
		}
		this.compService.setComparisonObs(comparison);
		this.initEachForms(comparison);
		this.initAlertSignals(comparison);
	}

	private setEnergySubtypeForDiesel(vehicleConfig: VehicleConfig) {
		if (!vehicleConfig.diesel.energySubtype) {
			vehicleConfig.diesel.energySubtype = new EnergySubtype();
			vehicleConfig.diesel.energySubtype.id = 1;
			vehicleConfig.diesel.energySubtype.name = GlobalConstant.BX;
			vehicleConfig.diesel.energySubtype.isDefault = true;
		}
	}

	private initEachForms(comparison: Comparison) {
		const comparisonTypeId = comparison.globalInfo.comparisonType.id;
		this.initVehicleConfigForm(comparison);
		this.globalInfoFormService.initGlobalForm(comparison.globalInfo);
		this.vehicleInfoFormService.initVehicleUsageForm(comparison.vehicleInfo.vehicleUsage);
		this.vehicleInfoFormService.initEnergyUsageForm(comparison.vehicleInfo.energyUsage, comparisonTypeId);
		this.vehicleCostFormService.initFinancialForm(comparison.vehicleCost.financials, comparisonTypeId, comparison.globalInfo.isDetail);
		this.vehicleCostFormService.initEnergyCostForm(comparison.vehicleCost.energyCost, comparisonTypeId);
		if (comparison.vehicleInfo.vehicleConfig.diesel) {
			this.vehicleCostFormService.initImpactCo2Form(
				comparison.vehicleCost.impactCo2,
				comparisonTypeId,
				comparison.vehicleInfo.vehicleConfig.diesel.energySubtype?.name
			);
		} else {
			this.vehicleCostFormService.initImpactCo2Form(comparison.vehicleCost.impactCo2, comparisonTypeId);
		}
		this.vehicleCostFormService.initIncentivesForm(comparison.vehicleCost.energyIncentivesTaxe, comparisonTypeId);
		this.vehicleCostFormService.initTransportCostForm(comparison.vehicleCost.transportCost, comparisonTypeId);
	}

	private initAlertSignals(comparison: Comparison) {
		this.alertsSignalsService.clearAlertSignal();
		this.alertsSignalsService.resetAllAlerts();
		this.populateAlertsBasedOnDetailStatus(comparison);
		this.getAlertsForComparison(comparison);
	}

	private populateAlertsBasedOnDetailStatus(comparison: Comparison) {
		if (comparison.globalInfo.isDetail) {
			this.alertsSignalsService.populeAlertSignalsListForDetailed();
		} else {
			this.alertsSignalsService.populeAlertSignalsListForMonthly();
		}
	}

	private getAlertsForComparison(comparison: Comparison) {
		const controls = comparison.globalInfo.isDetail
			? this.vehicleCostFormService.detailsFinancialForm?.controls
			: this.vehicleCostFormService.monthlyFeesForm?.controls;

		this.alertsSignalsService.getAlertSignals(this.compService.initComparisonsSelected(comparison), false, true, controls);
	}

	/**
	 * Get  Model and Battery and update Electric Vehicle-config Form
	 */
	private initVehicleConfigForm(comparison: Comparison): void {
		this.findElectricModel(comparison.vehicleInfo.vehicleConfig, comparison.globalInfo.comparisonType.id)
			.pipe(
				switchMap(() => {
					return this.configureEngineNumberForHDV(comparison);
				})
			)
			.subscribe(() => this.findElecBattery(comparison.vehicleInfo.vehicleConfig));
	}

	private configureEngineNumberForHDV(comparison: Comparison) {
		if (comparison.globalInfo.range.id === GlobalConstant.RANGE_HDV) {
			this.switchPtacToGcwForAllEnergy(comparison);
			return this.findEngineNumber(comparison.vehicleInfo.vehicleConfig).pipe(
				tap(() => {
					this.vehicleInfoFormService.addEngineNumberInVehicleConfigForm(
						GlobalConstant.ELECTRIC,
						comparison.vehicleInfo.vehicleConfig
					);
					this.vehicleInfoFormService.addEngineNumberInVehicleConfigForm(
						GlobalConstant.ELEC_COMP,
						comparison.vehicleInfo.vehicleConfig
					);
				})
			);
		} else {
			this.vehicleInfoFormService.removeEngineNumberInVehicleConfigForm(GlobalConstant.ELECTRIC);
			return of(null);
		}
	}

	private findEngineNumber(vehicleConfig: VehicleConfig): Observable<EngineNumber> {
		if (vehicleConfig.electric.engineNumber?.id) {
			return this.dataService.getEngineNumberById(vehicleConfig.electric.engineNumber.id).pipe(
				tap((engineNumber) => {
					vehicleConfig.electric.engineNumber = engineNumber;
				})
			);
		}
		return of();
	}

	private findElecBattery(vehicleConfig: VehicleConfig): void {
		if (vehicleConfig.electric.battery?.code) {
			this.dataService.getBatteryByCode(vehicleConfig.electric.battery.code).subscribe({
				next: (result) => {
					vehicleConfig.electric.battery = result;
					this.vehicleInfoFormService.vehicleConfigForm.patchValue({ electric: { ['battery']: result } });
				},
			});
		} else {
		}
		this.vehicleInfoFormService.vehicleConfigForm.patchValue({ electric: { ['battery']: new BatteryVersion() } });
	}

	private findElectricModel(vehicleConfig: VehicleConfig, comparisonType: number): Observable<ModelEnergy> {
		if (vehicleConfig.electric.model?.code) {
			return this.dataService.getModelByCode(vehicleConfig.electric.model.code, this.getNavigatorLang()).pipe(
				tap((model) => {
					vehicleConfig.electric.model = model;
					this.vehicleInfoFormService.initVehicleConfigForm(vehicleConfig, comparisonType);
				})
			);
		} else {
			this.vehicleInfoFormService.initVehicleConfigForm(vehicleConfig, comparisonType);
		}
		return of();
	}

	/**
	 * Change PTAC to GCW for each energy in Vehicle-config form depending on range changed
	 * @param comparison
	 */
	public switchPtacToGcwForAllEnergy(comparison: Comparison): void {
		this.compService.initComparisonsSelected(comparison).forEach((energy) => {
			this.vehicleInfoFormService.switchPtacToGcwByEnergy(
				comparison.globalInfo.range.id,
				comparison.vehicleInfo.vehicleConfig,
				energy.name
			);
		});
	}

	/**  Get the language from the navigator */
	private getNavigatorLang(): string {
		return navigator.language.slice(0, 2).toLowerCase();
	}

	public canDeactivate(): void {
		const isTcoFormsInvalid =
			this.vehicleInfoFormService.vehicleUsageForm.invalid ||
			this.vehicleInfoFormService.vehicleConfigForm.invalid ||
			this.vehicleInfoFormService.energyUsageForm.invalid ||
			this.vehicleCostFormService.energyCostForm.invalid ||
			this.vehicleCostFormService.incentivesForm.invalid ||
			this.vehicleCostFormService.impactCo2Form.invalid ||
			this.vehicleCostFormService.transportCostForm.invalid;

		const isDetailFinanInvalid = this.vehicleCostFormService.detailsFinancialForm
			? this.vehicleCostFormService.detailsFinancialForm?.invalid
			: false;
		const isMonthlyFinanInvalid = this.vehicleCostFormService.monthlyFeesForm
			? this.vehicleCostFormService.monthlyFeesForm?.invalid
			: false;

		if (isTcoFormsInvalid || isDetailFinanInvalid || isMonthlyFinanInvalid) {
			this.preventToResultPage();
			this.utils.displayErrorMessage('button.fixErrors');
			this.vehicleInfoFormService.vehicleUsageForm.markAllAsTouched();
			this.vehicleInfoFormService.vehicleConfigForm.markAllAsTouched();
			this.vehicleInfoFormService.energyUsageForm.markAllAsTouched();
			this.vehicleCostFormService.incentivesForm.markAllAsTouched();
			this.vehicleCostFormService.energyCostForm.markAllAsTouched();
			this.vehicleCostFormService.impactCo2Form.markAllAsTouched();
			this.vehicleCostFormService.incentivesForm.markAllAsTouched();
			this.vehicleCostFormService.transportCostForm.markAllAsTouched();
			this.vehicleCostFormService.monthlyFeesForm?.markAllAsTouched();
			this.vehicleCostFormService.detailsFinancialForm?.markAllAsTouched();
			this.isBlock = true;
			return;
		}
		this.isBlock = false;
	}

	private preventToResultPage(): void {
		const currentRoute = this.router.routerState;
		this.router.navigateByUrl(currentRoute.snapshot.url, {
			skipLocationChange: true,
		});
	}
}
