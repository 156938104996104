import { EnergyCostDto } from '@_dto/global/vehicleCostDto/energyCostDto';
import { FinancialsDto } from '@_dto/global/vehicleCostDto/financialsDto';
import { IncentivesDto } from '@_dto/global/vehicleCostDto/incentivesDto';
import { TransportDto } from '@_dto/global/vehicleCostDto/transportDto';
import { EmissionSource } from '@_models/vehicleCost/impactCo2/emissionSource';
import { Injectable } from '@angular/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { ChargingStationDetail } from '@shared/models/business/chargingStationDetail';
import { ComparisonDto } from '../../_dto/global/comparisonDto';
import { VehicleCost } from '../../_models/vehicleCost';
import { EnergyCost } from '../../_models/vehicleCost/energyCost/energyCost';
import { TotalEnergyCost } from '../../_models/vehicleCost/energyCost/totalEnergyCost';
import { Financials } from '../../_models/vehicleCost/financials/financials';
import { FinancialsEnergy } from '../../_models/vehicleCost/financials/financialsEnergy';
import { CountryEmissionFactor } from '../../_models/vehicleCost/impactCo2/countryEmissionFactor';
import { EmissionFactor } from '../../_models/vehicleCost/impactCo2/emissionFactor';
import { EnergyIncentivesTax } from '../../_models/vehicleCost/incentives/energyIncentive';
import { IncentivesTaxe } from '../../_models/vehicleCost/incentives/incentivesTaxe';
import { TaxReductDetails } from '../../_models/vehicleCost/incentives/taxReductDetails';
import { UpfrontIncentive } from '../../_models/vehicleCost/incentives/upfrontIncentive';
import { EnergyTransportCost } from '../../_models/vehicleCost/transport/energyTransportCost';
import { TransportCost } from '../../_models/vehicleCost/transport/transportCost';

@Injectable({
	providedIn: 'root',
})
export class VehicleCostService {
	/**  Init Default Vehicle Cost if api are not load from a save */
	public loadDefaultVehicleCost(comparisonId: number): VehicleCost {
		let vehicleCost = new VehicleCost();
		vehicleCost.financials = new FinancialsEnergy();
		vehicleCost.financials.feedbacks = '';
		vehicleCost.energyCost = new TotalEnergyCost();
		vehicleCost.transportCost = new EnergyTransportCost();
		vehicleCost.impactCo2 = new CountryEmissionFactor();
		vehicleCost.energyIncentivesTaxe = new EnergyIncentivesTax();
		this.loadDefaultVehicleCostByComparisonType(vehicleCost, comparisonId);
		return vehicleCost;
	}

	/**  Init Default Vehicle Config By Comparison type */
	public loadDefaultVehicleCostByComparisonType(vehicleCost: VehicleCost, comparisonId: number) {
		this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.ELECTRIC);
		this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.ELECTRIC);
		this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.ELECTRIC);
		this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.ELECTRIC);
		this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.ELECTRIC);

		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.DIESEL);
				this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.DIESEL);
				this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.DIESEL);
				this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.DIESEL);
				this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.DIESEL);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.DIESEL);
				this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.DIESEL);
				this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.DIESEL);
				this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.DIESEL);
				this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.DIESEL);
				this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.GAS);
				this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.GAS);
				this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.GAS);
				this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.GAS);
				this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EG:
				this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.GAS);
				this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.GAS);
				this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.GAS);
				this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.GAS);
				this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EE:
				this.loadDefaultFinancialsByEnergy(vehicleCost.financials, GlobalConstant.ELEC_COMP);
				this.loadDefaultEnergyCostByEnergy(vehicleCost.energyCost, GlobalConstant.ELEC_COMP);
				this.loadDefaultImpactCo2ByEnergy(vehicleCost.impactCo2, GlobalConstant.ELEC_COMP);
				this.loadDefaultTransportCostByEnergy(vehicleCost.transportCost, GlobalConstant.ELEC_COMP);
				this.loadDefaultEnergyIncentivesTaxe(vehicleCost.energyIncentivesTaxe, GlobalConstant.ELEC_COMP);
				break;
		}
	}

	/**  Init Default Detail-Financials By Energy */
	public loadDefaultFinancialsByEnergy(financials: FinancialsEnergy, energy: string) {
		let financialsMock: any = financials;
		financialsMock[energy] = new Financials();
		financialsMock[energy].truck = 0;
		financialsMock[energy].body = 0;
		financialsMock[energy].residualValue = 0;
		financialsMock[energy].interest = 0;
		financialsMock[energy].charger = 0;
		financialsMock[energy].smartCharge = 0;
		financialsMock[energy].monthlyFeesTruck = 0;
		financialsMock[energy].inclBody = 0;
		financialsMock[energy].inclUpfront = 0;
		financialsMock[energy].rmContract = 0;
		financialsMock[energy].monthlyFeeCharger = 0;
		financialsMock[energy].inclUpfrontCharger = 0;
		financials = financialsMock;
	}

	/**  Init Default Energy-Cost By Energy */
	loadDefaultEnergyCostByEnergy(energyCost: TotalEnergyCost, energy: string) {
		let energyCostMock: any = energyCost;
		energyCostMock[energy] = new EnergyCost();
		energyCostMock[energy].chargingStationList = new Array<ChargingStationDetail>();
		energyCostMock[energy].cost = 0;
		energyCostMock[energy].yearlyInflation = 0;
		energyCostMock[energy].adblue = 0;
		energyCostMock[energy].dieselCost = 0;
		energyCost = energyCostMock;
	}

	/**  Init Default ImpactCo2 By Energy */
	public loadDefaultImpactCo2ByEnergy(impactCo2: CountryEmissionFactor, energy: string) {
		let impactCo2Mock: any = impactCo2;
		impactCo2Mock[energy] = new Array<EmissionFactor>();
		impactCo2Mock[energy].emissionSource = new EmissionSource();
		impactCo2Mock.averageCost = 0;
		impactCo2Mock.averageCostState = false;
		impactCo2 = impactCo2Mock;
	}

	/**  Init Default Transport-Cost By Energy */
	public loadDefaultTransportCostByEnergy(transportCost: EnergyTransportCost, energy: string) {
		let transportCostMock: any = transportCost;
		transportCostMock[energy] = new TransportCost();
		transportCostMock[energy].body = 0;
		transportCostMock[energy].tyres = 0;
		transportCostMock[energy].insurance = 0;
		transportCostMock[energy].road = 0;
		transportCostMock[energy].driver = 0;
		transportCostMock[energy].fixed = 0;
		transportCostMock[energy].variable = 0;
		if (energy === GlobalConstant.GAS) {
			transportCostMock[energy].refuellingtime = 0;
		}
		transportCost = transportCostMock;
	}

	/**  Init Default Details-Financials By Energy */
	public loadDefaultEnergyIncentivesTaxe(energyIncentivesTaxe: EnergyIncentivesTax, energy: string) {
		let energyIncentivesTaxesMock: any = energyIncentivesTaxe;
		energyIncentivesTaxesMock.incomeTax = 0;
		energyIncentivesTaxesMock[energy] = new IncentivesTaxe();
		energyIncentivesTaxesMock[energy].upfront = 0;
		energyIncentivesTaxesMock[energy].upfrontSaved = 0;
		energyIncentivesTaxesMock[energy].upfrontState = false;
		energyIncentivesTaxesMock[energy].upfronts = this.populateUpfrontIncentives();

		energyIncentivesTaxesMock[energy].taxReduct = 0;
		energyIncentivesTaxesMock[energy].taxOver = 0;
		energyIncentivesTaxesMock[energy].taxReductSaved = 0;
		energyIncentivesTaxesMock[energy].taxReductState = false;

		energyIncentivesTaxesMock[energy].taxReductDetails = new TaxReductDetails();
		energyIncentivesTaxesMock[energy].taxReductDetails.profitMonth = 0;
		energyIncentivesTaxesMock[energy].taxReductDetails.profitTot = 0;
		energyIncentivesTaxesMock[energy].taxReductDetails.profitYear = 0;
		energyIncentivesTaxesMock[energy].taxReductDetails.totOver = 0;

		energyIncentivesTaxesMock[energy].notUpfront = 0;
		energyIncentivesTaxesMock[energy].notUpfrontSaved = 0;
		energyIncentivesTaxesMock[energy].notUpfrontState = false;
		energyIncentivesTaxesMock[energy].notUpfronts = this.populateNotUpfrontIncentives();

		energyIncentivesTaxesMock[energy].toll = 0;
		energyIncentivesTaxesMock[energy].tollSaved = 0;
		energyIncentivesTaxesMock[energy].tollState = false;

		energyIncentivesTaxesMock[energy].sharePercent = 0;
		energyIncentivesTaxesMock[energy].shareKm = 0;

		energyIncentivesTaxe = energyIncentivesTaxesMock;
	}

	// TODO: Will be change into list in the back end (later)
	private populateUpfrontIncentives(): UpfrontIncentive[] {
		let upfrontGroup = new Array<UpfrontIncentive>();
		let i1 = new UpfrontIncentive();
		i1.name = 'Incentive 1';
		i1.value = 0;

		let i2 = new UpfrontIncentive();
		i2.name = 'Incentive 2';
		i2.value = 0;

		let i3 = new UpfrontIncentive();
		i3.name = 'Incentive 3';
		i3.value = 0;

		let i4 = new UpfrontIncentive();
		i4.name = 'Incentive 4';
		i4.value = 0;

		let i5 = new UpfrontIncentive();
		i5.name = 'Incentive 5';
		i5.value = 0;

		let i6 = new UpfrontIncentive();
		i6.name = 'Incentive 6';
		i6.value = 0;

		upfrontGroup.push(i1, i2, i3, i4, i5, i6);
		return upfrontGroup;
	}

	// TODO: Will be change into list in the back end (later)
	private populateNotUpfrontIncentives(): UpfrontIncentive[] {
		let upfrontGroup = new Array<UpfrontIncentive>();
		let i1 = new UpfrontIncentive();
		i1.name = 'Incentive/Tax 1';
		i1.value = 0;

		let i2 = new UpfrontIncentive();
		i2.name = 'Incentive/Tax 2';
		i2.value = 0;

		let i3 = new UpfrontIncentive();
		i3.name = 'Incentive/Tax 3';
		i3.value = 0;

		let i4 = new UpfrontIncentive();
		i4.name = 'Incentive/Tax 4';
		i4.value = 0;

		let i5 = new UpfrontIncentive();
		i5.name = 'Incentive/Tax 5';
		i5.value = 0;

		let i6 = new UpfrontIncentive();
		i6.name = 'Incentive/Tax 6';
		i6.value = 0;

		upfrontGroup.push(i1, i2, i3, i4, i5, i6);
		return upfrontGroup;
	}

	public loadVehicleCostData(compDto: ComparisonDto): VehicleCost {
		let vc = new VehicleCost();
		vc.financials = new FinancialsEnergy();
		vc.financials.electric = this.getFinancialsFromDto(compDto.energies[0].financials);
		vc.financials.feedbacks = compDto.energies[0].financials.feedbacks;
		vc.energyCost = new TotalEnergyCost();
		vc.energyCost.electric = this.getEnergyCostFromDto(compDto.energies[0].energyCost);
		vc.energyIncentivesTaxe = new EnergyIncentivesTax();
		vc.energyIncentivesTaxe.electric = this.getIncentivesTaxeFromDto(compDto.energies[0].incentives);
		vc.energyIncentivesTaxe.incomeTax = compDto.energies[0].incentives.incomeTax;
		vc.impactCo2 = new CountryEmissionFactor();
		vc.impactCo2.averageCost = compDto.energies[0].penalty.averageCost;
		vc.impactCo2.averageCostState = compDto.energies[0].penalty.averageCostState;
		vc.impactCo2.electric = [new EmissionFactor()];
		vc.impactCo2.electric[0].value = compDto.energies[0].emissionFactor;
		vc.impactCo2.electric[0].emissionSource = new EmissionSource();
		vc.impactCo2.electric[0].emissionSource.id = compDto.energies[0].emissionSource.id;
		vc.impactCo2.electric[0].emissionSource.textSource = compDto.energies[0].emissionSource.textSource;
		vc.transportCost = new EnergyTransportCost();
		vc.transportCost.electric = this.getTransportCostFromDto(compDto.energies[0].transport);
		this.initVehicleCostByEnergy(compDto, vc);
		return vc;
	}

	private initVehicleCostByEnergy(compDto: ComparisonDto, vc: VehicleCost) {
		switch (compDto.comparisonMetadata.comparison) {
			case GlobalConstant.COMPTYPE_ED:
				vc.financials.diesel = this.getFinancialsFromDto(compDto.energies[1].financials);
				vc.energyCost.diesel = this.getEnergyCostFromDto(compDto.energies[1].energyCost);
				vc.impactCo2.diesel = [new EmissionFactor()];
				vc.impactCo2.diesel[0].value = compDto.energies[1].emissionFactor;
				vc.impactCo2.diesel[0].emissionSource = new EmissionSource();
				vc.impactCo2.diesel[0].emissionSource.id = compDto.energies[1].emissionSource.id;
				vc.impactCo2.diesel[0].emissionSource.textSource = compDto.energies[1].emissionSource.textSource;
				vc.energyIncentivesTaxe.diesel = this.getIncentivesTaxeFromDto(compDto.energies[1].incentives);
				vc.transportCost.diesel = this.getTransportCostFromDto(compDto.energies[1].transport);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				vc.financials.diesel = this.getFinancialsFromDto(compDto.energies[1].financials);
				vc.energyCost.diesel = this.getEnergyCostFromDto(compDto.energies[1].energyCost);
				vc.financials.gas = this.getFinancialsFromDto(compDto.energies[2].financials);
				vc.energyCost.gas = this.getEnergyCostFromDto(compDto.energies[2].energyCost);
				vc.impactCo2.diesel = [new EmissionFactor()];
				vc.impactCo2.diesel[0].value = compDto.energies[1].emissionFactor;
				vc.impactCo2.diesel[0].value = compDto.energies[1].emissionFactor;
				vc.impactCo2.diesel[0].emissionSource = new EmissionSource();
				vc.impactCo2.diesel[0].emissionSource.id = compDto.energies[1].emissionSource.id;
				vc.impactCo2.diesel[0].emissionSource.textSource = compDto.energies[1].emissionSource.textSource;
				vc.impactCo2.gas = [new EmissionFactor()];
				vc.impactCo2.gas[0].value = compDto.energies[2].emissionFactor;
				vc.impactCo2.gas[0].value = compDto.energies[2].emissionFactor;
				vc.impactCo2.gas[0].emissionSource = new EmissionSource();
				vc.impactCo2.gas[0].emissionSource.id = compDto.energies[2].emissionSource.id;
				vc.impactCo2.gas[0].emissionSource.textSource = compDto.energies[2].emissionSource.textSource;
				vc.energyIncentivesTaxe.diesel = this.getIncentivesTaxeFromDto(compDto.energies[1].incentives);
				vc.transportCost.diesel = this.getTransportCostFromDto(compDto.energies[1].transport);
				vc.energyIncentivesTaxe.gas = this.getIncentivesTaxeFromDto(compDto.energies[2].incentives);
				vc.transportCost.gas = this.getTransportCostFromDto(compDto.energies[2].transport);
				break;
			case GlobalConstant.COMPTYPE_EG:
				vc.financials.gas = this.getFinancialsFromDto(compDto.energies[1].financials);
				vc.energyCost.gas = this.getEnergyCostFromDto(compDto.energies[1].energyCost);
				vc.energyIncentivesTaxe.gas = this.getIncentivesTaxeFromDto(compDto.energies[1].incentives);
				vc.impactCo2.gas = [new EmissionFactor()];
				vc.impactCo2.gas[0].value = compDto.energies[1].emissionFactor;
				vc.impactCo2.gas[0].emissionSource = new EmissionSource();
				vc.impactCo2.gas[0].emissionSource.id = compDto.energies[1].emissionSource.id;
				vc.impactCo2.gas[0].emissionSource.textSource = compDto.energies[1].emissionSource.textSource;
				vc.transportCost.gas = this.getTransportCostFromDto(compDto.energies[1].transport);
				break;
			case GlobalConstant.COMPTYPE_EE:
				vc.financials.electricComp = this.getFinancialsFromDto(compDto.energies[1].financials);
				vc.energyCost.electricComp = this.getEnergyCostFromDto(compDto.energies[1].energyCost);
				vc.energyIncentivesTaxe.electricComp = this.getIncentivesTaxeFromDto(compDto.energies[1].incentives);
				vc.transportCost.electricComp = this.getTransportCostFromDto(compDto.energies[1].transport);
				vc.transportCost.electricComp = this.getTransportCostFromDto(compDto.energies[1].transport);
				break;
		}
	}

	private getFinancialsFromDto(financials: FinancialsDto): Financials {
		let f = new Financials();
		f.truck = financials.truck ? financials.truck : 0;
		f.body = financials.body ? financials.body : 0;
		f.residualValue = financials.residualValue ? financials.residualValue : 0;
		f.interest = financials.interest ? financials.interest : 0;
		f.rmContract = financials.rmContract ? financials.rmContract : 0;
		f.charger = financials.charger ? financials.charger : 0;
		f.smartCharge = financials.smartCharge ? financials.smartCharge : 0;
		f.monthlyFeesTruck = financials.monthlyFeesTruck ? financials.monthlyFeesTruck : 0;
		f.inclBody = financials.inclBody ? financials.inclBody : 0;
		f.inclUpfront = financials.inclUpfront ? financials.inclUpfront : 0;
		f.monthlyFeeCharger = financials.monthlyFeeCharger ? financials.monthlyFeeCharger : 0;
		f.inclUpfrontCharger = financials.inclUpfrontCharger ? financials.inclUpfrontCharger : 0;
		return f;
	}

	private getEnergyCostFromDto(energyCost: EnergyCostDto): EnergyCost {
		let ec = new EnergyCost();
		ec.cost = energyCost.cost ? energyCost.cost : 0;
		ec.yearlyInflation = energyCost.inflation ? energyCost.inflation : 0;
		ec.adblue = energyCost.adblue ? energyCost.adblue : 0;
		ec.dieselCost = energyCost.dieselCost ? energyCost.dieselCost : 0;
		ec.chargingStationList = energyCost.chargingStationsDetails ?? [];
		for (let charge of ec.chargingStationList) {
			delete charge.id;
		}
		return ec;
	}

	private getIncentivesTaxeFromDto(incentiveDto: IncentivesDto): IncentivesTaxe {
		let incentive = new IncentivesTaxe();
		incentive.upfront = incentiveDto.upfront ? incentiveDto.upfront : 0;
		incentive.upfrontState = incentiveDto.upfrontState;
		incentive.taxReduct = incentiveDto.taxReduct ? incentiveDto.taxReduct : 0;
		incentive.taxReductSaved = incentive.taxReduct;
		incentive.taxOver = incentiveDto.taxOver ? incentiveDto.taxOver : 0;
		incentive.taxReductState = incentiveDto.taxReductState;
		incentive.notUpfront = incentiveDto.notUpfront ? incentiveDto.notUpfront : 0;
		incentive.notUpfrontSaved = incentiveDto.notUpfront;
		incentive.notUpfrontState = incentiveDto.notUpfrontState;
		incentive.toll = incentiveDto.toll ? incentiveDto.toll : 0;
		incentive.tollSaved = incentiveDto.toll;
		incentive.tollState = incentiveDto.tollState;
		incentive.sharePercent = incentiveDto.sharePercent ? incentiveDto.sharePercent : 0;
		incentive.shareKm = incentiveDto.shareKm ? incentiveDto.shareKm : 0;
		incentive.upfronts = this.initUpfrontList(incentiveDto.upfrontGroup);
		incentive.upfrontSaved = incentive.upfronts.length > 0 ? this.computeUpfrontIncentiveSaved(incentive.upfronts) : 0;
		incentive.notUpfronts = this.initNotUpfrontList(incentiveDto.notUpfrontGroup);
		incentive.notUpfrontSaved = incentive.notUpfronts.length > 0 ? this.computeUpfrontIncentiveSaved(incentive.notUpfronts) : 0;
		incentive.taxReductDetails = new TaxReductDetails();
		return incentive;
	}

	private computeUpfrontIncentiveSaved(list: UpfrontIncentive[]): number {
		return list.reduce((total, elm) => total + elm.value, 0);
	}

	// TODO: Will be change into list in the back end (later)
	private initUpfrontList(upfronts: any): UpfrontIncentive[] {
		let upfrontGroup = new Array<UpfrontIncentive>();

		let i1 = new UpfrontIncentive();
		i1.name = upfronts.upfront1Label;
		i1.value = upfronts.upfront1;

		let i2 = new UpfrontIncentive();
		i2.name = upfronts.upfront2Label;
		i2.value = upfronts.upfront2;

		let i3 = new UpfrontIncentive();
		i3.name = upfronts.upfront3Label;
		i3.value = upfronts.upfront3;

		let i4 = new UpfrontIncentive();
		i4.name = upfronts.upfront4Label;
		i4.value = upfronts.upfront4;

		let i5 = new UpfrontIncentive();
		i5.name = upfronts.upfront5Label;
		i5.value = upfronts.upfront5;

		let i6 = new UpfrontIncentive();
		i6.name = upfronts.upfront6Label;
		i6.value = upfronts.upfront6;

		upfrontGroup.push(i1, i2, i3, i4, i5, i6);
		return upfrontGroup;
	}

	// TODO: Will be change into list in the back end (later)
	private initNotUpfrontList(upfronts: any): UpfrontIncentive[] {
		let upfrontGroup = new Array<UpfrontIncentive>();

		let i1 = new UpfrontIncentive();
		i1.name = upfronts.notUpfront1Label;
		i1.value = upfronts.notUpfront1;

		let i2 = new UpfrontIncentive();
		i2.name = upfronts.notUpfront2Label;
		i2.value = upfronts.notUpfront2;

		let i3 = new UpfrontIncentive();
		i3.name = upfronts.notUpfront3Label;
		i3.value = upfronts.notUpfront3;

		let i4 = new UpfrontIncentive();
		i4.name = upfronts.notUpfront4Label;
		i4.value = upfronts.notUpfront4;

		let i5 = new UpfrontIncentive();
		i5.name = upfronts.notUpfront5Label;
		i5.value = upfronts.notUpfront5;

		let i6 = new UpfrontIncentive();
		i6.name = upfronts.notUpfront6Label;
		i6.value = upfronts.notUpfront6;

		upfrontGroup.push(i1, i2, i3, i4, i5, i6);
		return upfrontGroup;
	}

	public getTransportCostFromDto(transport: TransportDto): TransportCost {
		let tc = new TransportCost();
		tc.body = transport.body ? transport.body : 0;
		tc.tyres = transport.tyres ? transport.tyres : 0;
		tc.insurance = transport.insurance ? transport.insurance : 0;
		tc.road = transport.road ? transport.road : 0;
		tc.driver = transport.driver ? transport.driver : 0;
		tc.fixed = transport.fixed ? transport.fixed : 0;
		tc.variable = transport.variable ? transport.variable : 0;
		tc.refuellingtime = transport.refuelling ?? 0;
		return tc;
	}
}
